.ascii-aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  height: 93%; }
  @media (min-height: 762px) and (max-height: 867px), (min-width: 772px) and (max-width: 877px) {
    .ascii-aligner {
      height: 92%; } }
  @media (min-height: 657px) and (max-height: 761px), (min-width: 667px) and (max-width: 771px) {
    .ascii-aligner {
      height: 91%; } }
  @media (min-height: 552px) and (max-height: 656px), (min-width: 562px) and (max-width: 666px) {
    .ascii-aligner {
      height: 88%; } }
  @media (max-height: 551px), (max-width: 561px) {
    .ascii-aligner {
      height: 86%; } }

.ascii {
  display: block;
  font-weight: bold;
  white-space: pre;
  font-family: monospace;
  color: #1a84db;
  line-height: 7px;
  font-size: 7px; }
  @media (min-height: 762px) and (max-height: 867px), (min-width: 772px) and (max-width: 877px) {
    .ascii {
      line-height: 6px;
      font-size: 6px; } }
  @media (min-height: 657px) and (max-height: 761px), (min-width: 667px) and (max-width: 771px) {
    .ascii {
      line-height: 5px;
      font-size: 5px; } }
  @media (min-height: 552px) and (max-height: 656px), (min-width: 562px) and (max-width: 666px) {
    .ascii {
      line-height: 4px;
      font-size: 4px; } }
  @media (max-height: 551px), (max-width: 561px) {
    .ascii {
      line-height: 3px;
      font-size: 3px; } }

.ascii-glow {
  text-shadow: 0 0 20px , 0 0 40px, 0 0 80px , 0 0 100px;
  color: greenyellow; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
