.toolbar {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  background: #643783;
  height: 56px;
  box-shadow: 0px 8px 16px 0px rgba(48, 74, 99, 0.4); }

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem; }

.toolbar__logo {
  margin-left: 1rem; }
  .toolbar__logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem; }

.spacer {
  flex: 1; }

.toolbar_navigation-items {
  height: 100%; }
  .toolbar_navigation-items ul {
    height: 100%;
    list-style: none;
    margin: 0;
    display: flex; }
    .toolbar_navigation-items ul li {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 0.5rem; }
  .toolbar_navigation-items a, .toolbar_navigation-items span, .toolbar_navigation-items li {
    color: #e7caff;
    cursor: pointer;
    text-decoration: none; }

.toolbar_navigation-items_dropdown {
  padding: 0;
  margin: 0; }

.toolbar_navigation-items_dropdown_button {
  display: flex;
  justify-content: center; }
  .toolbar_navigation-items_dropdown_button span {
    padding-top: 4px;
    font-size: 8px;
    padding-left: 5px; }

.toolbar_navigation-items_dropdown_content {
  display: none;
  position: absolute;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(48, 74, 99, 0.4);
  z-index: 1; }
  .toolbar_navigation-items_dropdown_content a {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left; }

.toolbar_navigation-items_links a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.5rem;
  margin: 0 -0.5rem; }

.toolbar_navigation-items_dropdown:hover .toolbar_navigation-items_dropdown_content {
  display: block;
  background: rgba(181, 133, 211, 0.986);
  color: white;
  transform: translate(-0.25rem, 14px); }

.toolbar_navigation-items li:hover {
  color: white; }
  .toolbar_navigation-items li:hover span {
    color: white; }

.toolbar_navigation-items_links:hover a {
  color: white; }

.toolbar_navigation-items_dropdown_content a:hover {
  color: white;
  background: rgba(109, 60, 155, 0.808); }

@media (max-width: 768px) {
  .toolbar_navigation-items {
    display: none; } }

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none; }
  .toolbar__logo {
    margin-left: 0; } }
