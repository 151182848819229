.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100; }

.closebutton {
  position: fixed;
  top: 15px;
  right: 25px;
  z-index: 300;
  width: 35px;
  height: 35px;
  cursor: pointer; }
  .closebutton div {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 25px;
    height: 3px;
    margin: 30px;
    background: white; }
  .closebutton .closebutton-line-1 {
    transform: rotate(45deg); }
  .closebutton .closebutton-line-2 {
    transform: rotate(135deg); }

@media (min-width: 770px) {
  .backdrop {
    display: none; } }
