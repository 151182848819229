.side-drawer_experiments-page {
  transition: transform 0.2s ease-in; }
  .side-drawer_experiments-page ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .side-drawer_experiments-page li {
    margin: 0.5rem 0; }
  .side-drawer_experiments-page a {
    color: #521751;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.5rem; }

.side-drawer_experiments-page.hide {
  display: none;
  transform: translateX(-350%); }

.side-drawer a:hover,
.side-drawer a:active {
  color: #fa923f; }
