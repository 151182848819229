.art-aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%; }

@media (min-width: 770px) {
  .spirograph_touch-device {
    display: none; } }

@media (max-width: 769px) {
  .spirograph_nontouch-device {
    display: none; } }
