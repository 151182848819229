html, body, .App, #root {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: linear-gradient(#ffc5cf, #b6e4ff);
  background-repeat: no-repeat;
  background-attachment: fixed; }
