.side-drawer_main-page {
  transition: transform 0.2s ease-in; }
  .side-drawer_main-page ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .side-drawer_main-page li {
    margin: 0.5rem 0; }
  .side-drawer_main-page a, .side-drawer_main-page .side-drawer_navigation-items_dropdown {
    color: #521751;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.5rem; }

.side-drawer_navigation-items_dropdown {
  display: flex;
  justify-content: center;
  align-items: center; }
  .side-drawer_navigation-items_dropdown .arrow {
    padding-left: 8px;
    font-size: 1.3rem; }

.side-drawer_main-page.hide {
  display: none;
  transform: translateX(-300%); }

.side-drawer a:hover,
.side-drawer a:active,
.side-drawer .side-drawer_navigation-items_dropdown:hover,
.side-drawer .side-drawer_navigation-items_dropdown:active {
  color: #fa923f; }
