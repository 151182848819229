.side-drawer {
  height: 100%;
  display: flex;
  justify-content: center;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out; }

.side-drawer.open {
  transform: translateX(0); }

.side-drawer_back-button {
  cursor: pointer;
  color: #521751;
  font-size: 1.5rem; }

.side-drawer_back-button:hover {
  color: #fa923f; }

@media (min-width: 770px) {
  .side-drawer {
    display: none; } }
